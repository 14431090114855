export default {
  DEFAULT_LOGO_URL: "https://thegrowing.co/static/media/logo.cdadb5d4.png",
  DASHBOARD_URL: "http://dashboard.thegrowing.co",
  SIGNUP_URL: "http://dashboard.thegrowing.co/signup",
  POLICY_URL: "https://thegrowing.co/policy/privacy",
  EULA_URL: "https://thegrowing.co/policy/eula",
  FRANCHISE_URL: "https://thegrowing.co/franchise-list",
  SUPPORT_URL: "https://support.thegrowing.co/growing-sales/",
  SUPPORT_AS:
    "https://support.thegrowing.co/growing-sales/policy/policy/store-policy",
  APPSTORE_URL:
    "https://apps.apple.com/us/app/%EA%B7%B8%EB%A1%9C%EC%9E%89%EC%84%B8%EC%9D%BC%EC%A6%88/id1471316655",
  PLAYSTORE_URL:
    "https://play.google.com/store/apps/details?id=com.thegrowing.sales",
  PC_DOWNLOAD: "https://download.thegrowing.co/GrowingSales.exe",

  // NEW CONSTANTS
  POS: "https://thegrowing.co/pos",
  RETAIL: "https://thegrowing.co/retail",
  MARKETING: "https://thegrowing.co/features/marketing",
  TABLE_ORDER: "https://thegrowing.co/features/table-order",
  KDS: "https://thegrowing.co/features/kds",
  WAITING: "https://thegrowing.co/features/waiting",
  KIOSK: "https://thegrowing.co/features/kiosk",
  STOCK: "https://thegrowing.co/features/stock",
  CAFE24: "https://thegrowing.co/features/cafe24",
  FRANCHISE: "https://thegrowing.co/features/growing-franchise",
  FEATURES: "https://thegrowing.co/features/all",
  CASE_STUDY_HOME: "https://thegrowing.co/case-study/franchise",
  GETTING_STARTED: "https://thegrowing.co/getting-started",
  CASE_SERVICE: "https://thegrowing.co//cases?type=service",
  CASE_FRANCHISE: "https://thegrowing.co//cases?type=franchise",
  CASE_FOOD: "https://thegrowing.co//cases?type=food",
  CASE_RETAIL: "https://thegrowing.co//cases?type=retail",
  FORM_SUBMISSION: "https://dashboard.thegrowing.co/pos/pos-setting",
  STORE: "https://smartstore.naver.com/growing-sales",
  STORE_WAITING:
    "https://smartstore.naver.com/growing-sales/products/10102497025",
  COMPATIBLE_CARD_DEVICES:
    "https://support.thegrowing.co/growing-sales/troubleshooting/faqs/card-terminal",
  APPSTORE_IOS:
    "https://apps.apple.com/kr/app/%EA%B7%B8%EB%A1%9C%EC%9E%89%EC%84%B8%EC%9D%BC%EC%A6%88/id1471316655",
  APPSTORE_ANDROID:
    "https://play.google.com/store/apps/details?id=com.thegrowing.sales",
  STORE_BLE: "https://smartstore.naver.com/growing-sales/products/5621115949",
  STORE_WLAN: "https://smartstore.naver.com/growing-sales/products/5364736490",
  RESERVE_DEMO:
    "https://docs.google.com/forms/d/e/1FAIpQLSfrydzJYtLdhjP2Y6Xyoz784mT_RXfRODCXwdCo5fGbqOhZrQ/viewform?usp=sf_link",
  KAKAO: "http://growingsales.channel.io/",
  JOB: "https://www.rocketpunch.com/companies/growingco/jobs",
  BLOG: "https://blog.naver.com/growingco",
  INSTAGRAM: "https://www.instagram.com/growing.sales/",
  YOUTUBE: "https://www.youtube.com/channel/UCMDf8GaWZtOlKJX9WRfp9qg",
  PAGE_MOVE_MARKETING: "/feature/marketing",
  PAGE_MOVE_CASE_STUDY: "/case-study/franchise",
  PAGE_MOVE_GETTING_STARTED: "/getting-started",
  PAGE_MOVE_ALL_FEATURES: "/features/all",
  PAGE_MOVE_POLICY: "/policy",
  TEAM_NOTION_LINK:
    "https://growingco.notion.site/Team-4ac9507e021b49e998362a55e327f5ab",
  YOUTUBE_LINKS: {
    bigwave: "https://youtu.be/eOxiDWe9Zfk",
    lefont: "https://youtu.be/DYmMuUFpJU4",
    integrationRetail: "https://youtu.be/0hvUjsL9dyQ",
    middlebottle: "https://youtu.be/C7qswF3NjY0",
    dancingcup: "https://youtu.be/ChyeQPcN-F0",
    thisisit: "https://youtu.be/GHIcGsmRwCc",
    oni: "https://youtu.be/NYkepd5DPg4",
    integrationKiosk: "https://youtu.be/IchL8GqFHLc",
    selfie: "https://youtu.be/83aML6k3Igc",
  },
  SUPPORT_ALL_FEATURES:
    "https://support.thegrowing.co/growing-sales/product/pos/comparison",
  SUPPORT_EMPLOYEE:
    "https://support.thegrowing.co/growing-sales/start/mgmt/employee",
  SUPPORT_KAKAO:
    "https://support.thegrowing.co/growing-sales/customer/kakao-business",
  SUPPORT_STOCK: "https://support.thegrowing.co/growing-sales/start/retail",
  SUPPORT_BARCODE:
    "https://support.thegrowing.co/growing-sales/device/peripheral/scanner",
  SUPPORT_STOCK_DEEP:
    "https://support.thegrowing.co/growing-sales/start/retail",
  SUPPORT_AROUND:
    "https://support.thegrowing.co/growing-sales/product/around-market",
  SUPPORT_RETAIL: "https://support.thegrowing.co/growing-sales/start/retail",
  STORE_KIOSK:
    "https://smartstore.naver.com/growing-sales/category/479e1cdaba7a44a9819ef499b17f169d?cp=1",
  STORE_TABLE_ORDER:
    "https://smartstore.naver.com/growing-sales/products/7623921375",
  STORE_EXTRA:
    "https://smartstore.naver.com/growing-sales/category/3629e75d69ec4137ae156a28dd30b87f?cp=1",
  RETAIL_REPORT:
    "https://support.thegrowing.co/growing-sales/start/retail/report",
  MARKETING_GUIDE:
    "https://support.thegrowing.co/growing-sales/customer/loyalty-program",
};
